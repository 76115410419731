<template name="component-name">
  <div class="account-record-template">
    <RecordScreen
      boxStyle="marginBottom: 24px;"
      :columns="columns"
      :filterArr="filterArr"
      :filterValue="field"
      :dateChange="dateChange"
      :download="selectDownload"
      :selectChange="filterChange"
      :pressChange="filterChange"
      :inputChange="filterChange"
      @checkedChange="filterCheckedChange"
    />
    <a-table
      :loading="loading"
      :columns="columns"
      :data-source="recordList"
      :row-selection="{
        selectedRowKeys: selectedRowKeys,
        onSelect: selectChange,
        onSelectAll: selectAllChange
      }"
      :pagination="false"
      :scroll="{ x: '100%', y: 640 }"
      @change="tableChange"
    >
      <template slot="iproject_id" slot-scope="text, record">
        <span
          >{{ record.iproject_id }}
          <Iconfont icon="icontos" style="font-size: 30px; padding-top: 9px" />
        </span>
      </template>
      <template slot="expirate_date" slot-scope="text, record">
        <span>{{ record.expirate_date }}</span>
      </template>
      <template slot="email_status" slot-scope="text, record">
        <span :class="record.email_state ? 'send' : 'unsend'">{{
          record.email_status
        }}</span>
      </template>
      <template slot="action" slot-scope="text, record">
        <div class="ar-template-operate">
          <template v-for="item in operateList">
            <a-tooltip :key="item.icon">
              <template slot="title">
                <span>{{ item.title }}</span>
              </template>
              <div :class="item.class" @click="operate(record, item.type)">
                <Iconfont :icon="item.icon" :styleObj="item.styleObj" />
                <!-- <span class="arto-preview-txt">预览</span> -->
              </div>
            </a-tooltip>
          </template>
          <a-popconfirm
            ok-text="是"
            cancel-text="否"
            @confirm="operate(record, 'delete')"
          >
            <template slot="title">
              <p>确定要删除吗？</p>
            </template>
            <a-tooltip>
              <template slot="title">
                <span>删除</span>
              </template>
              <div class="art-operate-delete">
                <Iconfont icon="icondelete" />
                <!-- <span class="arto-delete-txt">删除</span> -->
              </div>
            </a-tooltip>
          </a-popconfirm>
        </div>
      </template>
      <template slot="status" slot-scope="text, record">
        <span
          :class="{
            success: record.ak_state,
            // fail: record.ak_state === false ? true : false,
            expirate: record.ak_state === false ? true : false
          }"
          >{{ record.ak_status }}</span
        >
      </template>
    </a-table>
    <div class="ar-template-bottom">
      <div class="art-bottom-del">
        <a-checkbox
          @change="checkedAllChange"
          class="artb-del-checkbox"
          :disabled="!recordList.length"
          :indeterminate="indeterminate"
          :checked="checkedAll"
        />
        <a-popconfirm
          ok-text="是"
          cancel-text="否"
          :disabled="!(checkedAll || indeterminate)"
          @confirm="deleteFn"
        >
          <template slot="title">
            <p>确定要删除吗？</p>
          </template>
          <div
            :class="
              'artb-del-btn ' +
              (checkedAll || indeterminate ? 'artb-del-btn--active' : '')
            "
          >
            <span class="iconfont icondelete artbd-btn-icon"></span>
            删除
          </div>
        </a-popconfirm>
        <div
          :class="
            'art-bottom-download' +
            (downloadData.length ? ' art-bottom-download--active' : '')
          "
          @click="selectDownload('part')"
        >
          <span class="iconfont iconexport artb-download-icon"></span>
          <span class="artb-download-txt">导出</span>
        </div>
      </div>
      <div class="art-bottom-pagination" v-show="recordList.length">
        <PaginationComponent
          :current="page"
          :pageSize="count"
          :total="total"
          :change="paginationChange"
          :showSizeChange="paginationSizeChange"
        />
      </div>
    </div>
    <PreviewTosModal
      :previewModal="previewModal"
      :preview="previewObj"
      v-if="previewStatus"
    />
  </div>
</template>
<script>
import Vue from "vue";
import api from "./../../api";
import qee from "qf-export-excel";
import Iconfont from "./../../components/Iconfont";
import PreviewTosModal from "./../../components/modals/PreviewTosModal";
import RecordScreen from "./../../components/RecordScreen";
import PaginationComponent from "./../../components/paginations/PaginationComponent";
import {
  requestGetUserName,
  requestPostUserName,
  requestDeleteUserName,
  requestPatchUserName
} from "./../../request";
const columns = [
  {
    title: "开题单号",
    dataIndex: "iproject_id",
    key: "iproject_id",
    fixed: "left",
    sorter: true,
    width: 250,
    scopedSlots: { customRender: "iproject_id" }
  },
  {
    title: "合同号",
    dataIndex: "contract_id",
    key: "contract_id",
    sorter: true,
    width: 200
  },
  {
    title: "数据目录",
    dataIndex: "data_dir",
    key: "data_dir",
    width: 300
  },
  {
    title: "发送邮箱",
    dataIndex: "email",
    key: "email",
    width: 200
  },
  {
    title: "创建时间",
    dataIndex: "create_time",
    key: "create_time",
    sorter: true,
    width: 200
  },
  {
    title: "有效时间",
    dataIndex: "expir_time",
    key: "expir_time",
    sorter: true,
    width: 200
  },
  {
    title: "有效期（天）",
    dataIndex: "expirate_date",
    key: "expirate_date",
    width: 120,
    scopedSlots: { customRender: "expirate_date" }
  },
  {
    title: "AK状态",
    dataIndex: "ak_status",
    key: "ak_status",
    sorter: true,
    width: 120,
    scopedSlots: { customRender: "status" }
  },
  {
    title: "邮件发送状态",
    dataIndex: "email_status",
    key: "email_status",
    sorter: true,
    width: 150,
    scopedSlots: { customRender: "email_status" }
  },
  {
    title: "操作",
    dataIndex: "operate",
    fixed: "right",
    width: 180,
    abandon: true,
    scopedSlots: { customRender: "action" }
  }
];
const filterArr = [
  {
    name: "开题单号",
    value: "iproject_id"
  },
  {
    name: "合同号",
    value: "contract_id"
  },
  {
    name: "Ak状态",
    value: "ak_state"
  },
  {
    name: "邮件发送状态",
    value: "email_state"
  }
];

const expirateDate = (create, expirete) => {
  let cTime = new Date(create).getTime();
  let eTime = new Date(expirete).getTime();
  return parseInt((eTime - cTime) / (24 * 60 * 60 * 1000));
};

const sorterObj = {
  ascend: "asc",
  descend: "desc"
};

const asStateArr = [
  "成功",
  // "失败",
  "过期"
];
const asStateObj = {
  成功: true,
  // 失败: false,
  过期: false
};
const emailStateArr = ["已发送", "未发送"];
const emailStateObj = {
  已发送: true,
  未发送: false
};
export default {
  data() {
    return {
      total: 0,
      page: 1,
      count: 10,
      filterArr,
      words: "",
      loading: false,
      recordList: [],
      recordListOrigin: [],
      downloadData: [],
      indeterminate: false,
      checkedAll: false,
      selectedRowKeys: [],
      previewObj: {},
      previewStatus: false,
      operateList: [
        {
          icon: "iconpreview",
          style: "",
          title: "预览",
          type: "preview",
          class: "art-operate-preview"
        },
        {
          icon: "iconrefresh",
          style: "fontSize: 16px;",
          title: "重置",
          type: "reset",
          class: "art-operate-reset"
        },
        {
          icon: "iconmailbox1",
          style: "",
          title: "发送到邮箱",
          type: "email",
          class: "art-operate-email"
        }
      ],
      field: "iproject_id",
      date: ["", ""],
      orderby: "",
      order_reg: "",
      columns: JSON.parse(JSON.stringify(columns))
    };
  },
  computed: {
    currentRoute() {
      return this.$route;
    }
  },
  mounted() {
    this.deliveryList();
  },
  components: {
    Iconfont,
    PreviewTosModal,
    RecordScreen,
    PaginationComponent
  },
  methods: {
    dataFn(list) {
      let arr = [];
      list.forEach((item) => {
        arr.push({
          ...item,
          key: item.id,
          // ak_status: item.ak_state
          //   ? "成功"
          //   : item.ak_state === false
          //   ? "失败"
          //   : "过期",
          ak_status: item.ak_state ? "成功" : "过期",
          email_status: item.email_state ? "已发送" : "未发送",
          expirate_date: expirateDate(item.create_time, item.expir_time)
        });
      });
      return arr;
    },
    // 记录数据列表
    deliveryList(type) {
      let str = "";
      let { page, count, total, date, field, words, orderby, order_reg } = this;
      if (type === "all") {
        str = `?page=1&count=${total}`;
      } else {
        this.loading = true;
        str = `?page=${page}&count=${count}`;
        if (date[0]) {
          str += `&start=${date[0]}&end=${date[1]}`;
        }
        if (words) {
          str += `&field=${field}&words=${
            field === "ak_state"
              ? asStateObj[words]
              : field === "email_state"
              ? emailStateObj[words]
              : words
          }`;
        }
        if (orderby) {
          str += `&orderby=${orderby}&order_reg=${order_reg}`;
        }
      }
      requestGetUserName(api.volcano_delivery_list + str)
        .then(({ data }) => {
          if (data.code === 2000) {
            if (type === "all") {
              let header = columns.filter((item) => item.title !== "操作");
              qee(header, this.dataFn(data.result), "账号记录");
            } else {
              let list = this.dataFn(data.result);
              this.total = data.total;
              this.downloadData = [];
              this.checkedAll = false;
              this.selectedRowKeys = [];
              this.indeterminate = false;
              this.recordList = JSON.parse(JSON.stringify(list));
              this.recordListOrigin = JSON.parse(JSON.stringify(list));
            }
          } else if (data.code !== 4001) {
            this.$message.error(data.msg);
          }
          this.loading = false;
        })
        .catch(() => {});
    },
    // 删除记录
    deleteRecord(selectedRowKeys) {
      let fd = new FormData();
      fd.append("del_list", JSON.stringify(selectedRowKeys));
      fd.append(
        "backend",
        this.currentRoute.path === "/account/TOSRecord" ? "tos" : "oss"
      );
      requestDeleteUserName(api.record, {
        data: fd
      })
        .then(({ data }) => {
          if (data.code === 2000) {
            this.deliveryList();
            this.$message.success("删除成功！");
          } else if (data.code !== 4001) {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },
    // 预览信息
    akInfo(id) {
      requestGetUserName(api.ak_info, {
        id,
        backend: this.currentRoute.path === "/account/TOSRecord" ? "tos" : "oss"
      })
        .then(({ data }) => {
          if (data.code === 2000) {
            this.previewStatus = true;
            this.previewObj = data.ak_info;
          } else if (data.code !== 4001) {
            this.$message.error(data.msg);
            this.previewStatus = false;
          }
        })
        .catch(() => {});
    },
    // 发送邮箱
    email(id) {
      let fd = new FormData();
      fd.append("id", id);
      fd.append(
        "backend",
        this.currentRoute.path === "/account/TOSRecord" ? "tos" : "oss"
      );
      requestPostUserName(api.email, fd)
        .then(({ data }) => {
          if (data.code === 2000) {
            this.deliveryList();
            this.$message.success("已发送！");
          } else if (data.code !== 4001) {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },
    // 重置
    resetting(id) {
      let fd = new FormData();
      fd.append("id", id);
      fd.append(
        "backend",
        this.currentRoute.path === "/account/TOSRecord" ? "tos" : "oss"
      );
      requestPatchUserName(api.resetting, fd)
        .then(({ data }) => {
          if (data.code === 2000) {
            this.deliveryList();
            this.$message.success("重置成功！");
          } else if (data.code !== 4001) {
            this.$message.error(data.msg);
          }
        })
        .catch(() => {});
    },

    // 表格排序
    tableChange(pagination, filters, sorter, currentDataSource) {
      this.orderby = sorter.order
        ? sorter.field === "ak_status"
          ? "ak_state"
          : sorter.field === "email_status"
          ? "email_state"
          : sorter.field
        : "";
      this.order_reg = sorter.order ? sorterObj[sorter.order] : "";
      this.deliveryList();
    },
    // 单选
    selectChange(record, selected, selectedRows) {
      console.log(record, selected, selectedRows);
      let downloadData = [];
      let selectedRowKeys = [];
      if (selectedRows.length === this.recordList.length) {
        this.checkedAll = true;
        this.indeterminate = false;
      } else if (selectedRows.length) {
        this.checkedAll = false;
        this.indeterminate = true;
      } else {
        this.checkedAll = false;
        this.indeterminate = false;
      }
      selectedRows.forEach((item) => {
        selectedRowKeys.push(item.id);
        downloadData.push(item);
      });

      this.downloadData = downloadData;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 全选、取消
    selectAllChange(selected, selectedRows, changeRows) {
      console.log(selected, selectedRows, changeRows);
      let downloadData = [];
      this.checkedAll = selected;
      this.indeterminate = false;
      let selectedRowKeys = [];
      selectedRows.forEach((item) => {
        selectedRowKeys.push(item.id);
        downloadData.push(item);
      });
      this.downloadData = downloadData;
      this.selectedRowKeys = selectedRowKeys;
    },
    // 全选、取消
    checkedAllChange(e) {
      this.checkedAll = e.target.checked;
      this.indeterminate = false;
      let selectedRowKeys = [];
      this.recordList.forEach((item) => {
        selectedRowKeys.push(item.id);
      });
      this.downloadData = e.target.checked ? this.recordList : [];
      this.selectedRowKeys = e.target.checked ? selectedRowKeys : [];
    },
    // 删除
    deleteFn() {
      let { selectedRowKeys } = this;
      if (!selectedRowKeys.length) return;
      this.deleteRecord(selectedRowKeys);
    },
    // 操作
    operate(record, type) {
      if (type === "preview") {
        if (record.ak_state === false) {
          this.$message.warning("项目失败不能预览！");
          return;
        } else if (record.ak_state === null) {
          this.$message.warning("项目过期不能预览！");
          return;
        }
        this.akInfo(record.id);
      } else if (type === "reset") {
        // 项目失败能重置吗
        if (record.reset_count >= 3) {
          this.$message.warning("只能重置3次！");
          return;
        } else if (record.ak_state) {
          this.$message.warning("项目没有过期，不能重置！");
          return;
        }
        this.resetting(record.id);
      } else if (type === "email") {
        if (record.ak_state === false) {
          this.$message.warning("项目失败不能发送！");
          return;
        } else if (record.ak_state === null) {
          this.$message.warning("项目已过期，不能发送！");
          return;
        }
        this.email(record.id);
      } else {
        this.deleteRecord([record.id]);
      }
    },
    // 关闭预览
    previewModal() {
      this.previewStatus = false;
    },
    // 选择时间
    dateChange(monent, date) {
      this.date = date;
      if (!date[0]) {
        this.deliveryList();
      }
    },
    // 筛选条件、输入框
    filterChange(value, type) {
      if (type === "select") {
        this.field = value;
      } else if (type === "pressSeach") {
        if (this.field === "ak_state" && value) {
          if (asStateArr.indexOf(value) === -1) {
            this.$message.warning("请输入正确的AK状态！");
            return;
          }
        } else if (this.field === "email_state" && value) {
          if (emailStateArr.indexOf(value) === -1) {
            this.$message.warning("请输入正确的邮箱状态！");
            return;
          }
        }
        this.words = value;
        this.page = 1;
        this.deliveryList();
      } else {
        if (value.target.value) return;
        this.words = value.target.value;
        this.page = 1;
        this.deliveryList();
      }
    },
    // 导出
    selectDownload(type) {
      let { columns, downloadData, total } = this;
      if (type === "all") {
        if (!total) {
          this.$message.warning("暂无数！");
          return;
        }
        this.deliveryList(type);
      } else {
        if (!downloadData.length) return;
        let header = columns.filter((item) => item.title !== "操作");
        qee(header, downloadData, "账号记录");
      }
    },
    // 筛选
    filterCheckedChange(column) {
      this.columns = column;
      this.recordList =
        column.length > 1
          ? JSON.parse(JSON.stringify(this.recordListOrigin))
          : [];
    },
    paginationChange(page, pageSize) {
      this.page = page;
      this.deliveryList();
    },
    paginationSizeChange(current, size) {
      this.count = size;
      this.deliveryList();
    }
  }
};
</script>
<style lang="less" scoped>
@import "./../../style/accountRecord.less";
</style>
