<template>
  <div class="preview-modal-component">
    <div class="pm-component-content">
      <div class="pmc-content-title">
        <span class="pmcc-title-left"></span>
        <span class="pmcc-title-center">账号信息预览</span>
        <span
          class="iconfont iconclose pmcc-title-right"
          @click="previewModal"
        ></span>
      </div>
      <div
        @click="copyTable"
        id="copy"
        class="pmcc-title-copy"
        data-clipboard-target="#pmc-content-input"
      >
        <div class="pmcct-copy-btn">
          <span class="iconfont iconfuzhi pmcctc-btn-icon"></span>
          复制
        </div>
      </div>
      <a-table
        bordered
        :columns="columns"
        :data-source="data"
        :pagination="false"
        class="pmc-content-table"
        id="pmc-content-input"
      ></a-table>
    </div>
    <MessageComponent :visible="visible" />
  </div>
</template>
<script>
import ClipboardJS from "clipboard";
import Iconfont from "./../Iconfont";
import MessageComponent from "./../MessageComponent";
const columns = [
  {
    title: "类别",
    dataIndex: "type",
    width: 150
  },
  {
    title: "内容",
    dataIndex: "content"
  }
];
const customData = [
  {
    key: "合同号",
    value: "合同号"
  },
  {
    key: "开题单号",
    value: "开题单号"
  },
  {
    key: "分享链接",
    value: "分享链接"
  },
  {
    key: "区域",
    value: "区域"
  },
  {
    key: "授权码",
    value: "授权码"
  },
  {
    key: "预设TOS路径",
    value: "预设TOS路径"
  },
  {
    key: "过期时间",
    value: "过期时间"
  }
];
export default {
  props: {
    preview: {
      type: Object,
      default: {}
    },
    previewModal: {
      type: Function,
      default: function () {}
    }
  },
  data() {
    return {
      columns,
      data: [],
      visible: false
    };
  },
  mounted() {
    customData.forEach((item) => {
      const value =
        item.value === "分享链接" ? (
          <span>
            分享链接
            <div style="color: #FF0000">
              （请复制"共享链接"信息到TOS客户端输入框，点击【登入】，批量下载数据）
            </div>
          </span>
        ) : (
          item.value
        );
      this.data.push({
        key: item.key,
        type: value,
        content: this.preview[item.key]
      });
    });
  },
  components: {
    Iconfont,
    MessageComponent
  },
  methods: {
    copyTable() {
      this.visible = false;
      var clipboard = new ClipboardJS("#copy");
      clipboard.on("success", (e) => {
        this.visible = true;
        e.clearSelection();
      });
      clipboard.on("error", (e) => {});
    }
  }
};
</script>
<style lang="less">
@import "./../../style/previewModal.less";
</style>
